import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import Snackbar from "@mui/material/Snackbar";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import refreshAccessToken from '../authentication/authUtil';
import { CircularProgress, Typography } from "@mui/material";
import { Upload } from "@mui/icons-material";

function AddBookModal({ open, onClose, onAddBook }) {
    const [title, setTitle] = useState("");
    const [author, setAuthor] = useState("");
    const [language, setLanguage] = useState("");
    const [pdfFile, setPdfFile] = useState(null);
    const [coverImageFile, setCoverImageFile] = useState(null);
    const [pdfFileInputKey, setPdfFileInputKey] = useState(0);
    const [coverImageFileInputKey, setCoverImageFileInputKey] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [error, setError] = useState(null);
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [loading, setLoading] = useState(false);
    const [UploadStatistics, setUploadStatistics] = useState({});
    const [successModalOpen, setSuccessModalOpen] = useState(false);

    const handleAddBook = async () => {
        setLoading(true);
        setError(null);

        // Validate form data
        if (!title || !author || !language || !pdfFile) {
            setError("All fields are required.");
            return;
        }

        let newBook = {};

        try {
            const formData = new FormData();
            formData.append("file", pdfFile);
            formData.append("imagefile", coverImageFile || ''); // In case coverImageFile is null
            formData.append("title", title);
            formData.append("bookname", title)
            formData.append("language", language);
            formData.append("author", author);
            formData.append("start", start); // Replace with appropriate value
            formData.append("end", end); // Replace with appropriate value

            const newAccessToken = await refreshAccessToken();

            const response = await fetch(`${process.env.REACT_APP_API_URL}/book/upload`, {
                method: "POST",
                body: formData,
                headers: {
                    Authorization: `Bearer ${newAccessToken}`,
                },
            });

            if (response.ok) {
                newBook = await response.json();
                //console.log(newBook);
                setUploadStatistics(newBook);
                setSuccessModalOpen(true);

                onAddBook(newBook);
                onClose();
                setSnackbarMessage("Book uploaded successfully!");
                setSnackbarOpen(true);
            } else {
                console.error("Error saving the book");
                setSnackbarMessage("Error uploading the book");
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error("Error connecting to the server", error);
            setSnackbarMessage("Error uploading the book");
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };
    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
        handleModalClose();
    };

    const handleModalClose = () => {
        setPdfFile(null);
        setCoverImageFile(null);
        setTitle(null);
        setAuthor(null);
        setLanguage(null);
        setStart(null);
        setEnd(null);
        onClose();
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <div>
            <Modal open={open} onClose={handleModalClose}>
                <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    p={4}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        borderRadius: '8px',
                        padding: '20px',
                        width: '30%',
                    }}
                >
                    <SoftBox mb={2} sx={{ width: "100%" }}>
                        <SoftTypography component="h2" variant="h5" fontWeight="bold" mb={2}>
                            Add New Book
                        </SoftTypography>

                        {error && (
                            <SoftTypography variant="body2" color="error">
                                {error}
                            </SoftTypography>
                        )}

                        <SoftBox mb={2}>
                            <SoftInput
                                placeholder="Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </SoftBox>

                        <SoftBox mb={2}>
                            <SoftInput
                                placeholder="Author"
                                value={author}
                                onChange={(e) => setAuthor(e.target.value)}
                            />
                        </SoftBox>

                        <SoftBox mb={2}>
                            <SoftInput
                                placeholder="Language"
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                            />
                        </SoftBox>

                        <SoftBox mb={2}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Cover Image
                            </SoftTypography>
                            <label htmlFor="coverImageFile" style={{ display: "block", cursor: "pointer" }}>
                                <input
                                    id="coverImageFile"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    key={coverImageFileInputKey}
                                    onChange={(e) => setCoverImageFile(e.target.files[0])}
                                />
                                <SoftButton
                                    variant="outlined"
                                    component="span"
                                    color="primary"
                                    onClick={() => {
                                        setCoverImageFileInputKey((prevKey) => prevKey + 1);
                                        document.getElementById("coverImageFile").value = ""; // Reset the value of the input
                                    }}
                                >
                                    Choose Image
                                </SoftButton>
                            </label>
                            {coverImageFile && (
                                <SoftTypography variant="body2">{coverImageFile.name}</SoftTypography>
                            )}
                        </SoftBox>

                        <SoftBox mb={2}>
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                PDF File
                            </SoftTypography>
                            <label htmlFor="pdfFile" style={{ display: "block", cursor: "pointer" }}>
                                <input
                                    id="pdfFile"
                                    type="file"
                                    accept=".pdf"
                                    style={{ display: "none" }}
                                    key={pdfFileInputKey}
                                    onChange={(e) => setPdfFile(e.target.files[0])}
                                />
                                <SoftButton
                                    variant="outlined"
                                    component="span"
                                    color="primary"
                                    onClick={() => {
                                        setPdfFileInputKey((prevKey) => prevKey + 1);
                                        document.getElementById("pdfFile").value = ""; // Reset the value of the input
                                    }}
                                >
                                    Choose PDF
                                </SoftButton>
                            </label>
                            {pdfFile && <SoftTypography variant="body2">{pdfFile.name}</SoftTypography>}
                        </SoftBox>


                        <SoftBox mb={2}>
                            <SoftInput
                                placeholder="Page no. of starting content"
                                value={start}
                                onChange={(e) => setStart(e.target.value)}
                            />
                        </SoftBox>

                        <SoftBox mb={2}>
                            <SoftInput
                                placeholder="Page no. of ending content"
                                value={end}
                                onChange={(e) => setEnd(e.target.value)}
                            />
                        </SoftBox>
                        <SoftButton
                            variant="contained"
                            color="primary"
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={handleAddBook}
                        >
                            Add Book
                        </SoftButton>
                    </SoftBox>

                    {loading && (
                        <>
                            <SoftBox
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                p={4}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                    borderRadius: '8px',
                                    padding: '20px',
                                    width: '30%',
                                }}
                            >
                                <CircularProgress />
                            </SoftBox>
                        </>
                    )}



                </SoftBox>

            </Modal>


            <Modal open={successModalOpen} onClose={handleSuccessModalClose}>
                <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    p={4}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        borderRadius: '8px',
                        width: '60%',
                        maxWidth: '400px',  // Adjust the maximum width as needed
                    }}
                >
                    <SoftTypography variant="h6" style={{ fontWeight: 'bold', marginBottom: '16px' }}>
                        Book Information
                    </SoftTypography>

                    <SoftTypography variant="subtitle1" style={{ fontSize: '1rem', marginBottom: '8px' }}>
                        Book Title: {UploadStatistics.title}
                    </SoftTypography>
                    <SoftTypography variant="subtitle1" style={{ fontSize: '1rem', marginBottom: '8px' }}>
                        Author: {UploadStatistics.author}
                    </SoftTypography>
                    <SoftTypography variant="subtitle1" style={{ fontSize: '1rem', marginBottom: '8px' }}>
                        Language: {UploadStatistics.language}
                    </SoftTypography>
                    <SoftTypography variant="subtitle1" style={{ fontSize: '1rem', marginBottom: '8px' }}>
                        Total Words: {UploadStatistics.total_words}
                    </SoftTypography>
                    <SoftTypography variant="subtitle1" style={{ fontSize: '1rem', marginBottom: '8px' }}>
                        Unique Words: {UploadStatistics.unique_words}
                    </SoftTypography>
                    <SoftTypography variant="subtitle1" style={{ fontSize: '1rem', marginBottom: '8px' }}>
                        Static Extensive Words: {UploadStatistics.static_extensive_reading_words}
                    </SoftTypography>
                    <SoftTypography variant="subtitle1" style={{ fontSize: '1rem', marginBottom: '8px' }}>
                        Static Intensive Words: {UploadStatistics.static_intensive_reading_words}
                    </SoftTypography>

                    <SoftButton
                        variant="contained"
                        color="primary"
                        style={{ marginTop: '16px' }}
                        onClick={handleSuccessModalClose}
                    >
                        Close
                    </SoftButton>
                </SoftBox>
            </Modal>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
                <div
                    style={{
                        backgroundColor: snackbarMessage.includes("successfully") ? "#4CAF50" : "#f44336",
                        color: "white",
                        padding: "10px",
                        borderRadius: "4px",
                    }}
                >
                    {snackbarMessage}
                </div>
            </Snackbar>
        </div>
    );
}

AddBookModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAddBook: PropTypes.func.isRequired,
};

export default AddBookModal;

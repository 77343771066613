import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  Button,
  Snackbar,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { useParams } from 'react-router-dom';
import refreshAccessToken from 'layouts/authentication/authUtil';

import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

const PDFViewer = () => {
  const { bookId } = useParams();
  const [pdfData, setPdfData] = useState(null);
  const [wordContexts, setWordContexts] = useState([]);
  const [selectedWord, setSelectedWord] = useState(null);
  const [contextLines, setContextLines] = useState([]);
  const [dictionaryMeanings, setDictionaryMeanings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [sortAsc, setSortAsc] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 100;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append('bookname', bookId);
        formData.append('username', localStorage.getItem('username'));
        const newToken = await refreshAccessToken();
        const wordContextsResponse = await fetch(`${process.env.REACT_APP_API_URL}/book/wordcontexts`, {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${newToken}`,
          },
        });
        const wordContextsData = await wordContextsResponse.json();
        if (wordContextsData.BooksWordContexts && Array.isArray(wordContextsData.BooksWordContexts)) {
          setWordContexts(wordContextsData.BooksWordContexts);
        }

        const pdfResponse = await fetch(`${process.env.REACT_APP_API_URL}/book/get_pdf/${bookId}`, {
          headers: {
            Authorization: `Bearer ${newToken}`
          }
        });

        const resultBlob = await pdfResponse.blob();
        const url = URL.createObjectURL(resultBlob);
        setPdfData(url);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [bookId]);

  const fetchContextLines = async (word) => {
    const matchingContexts = wordContexts.filter((item) => item.word === word);
    return matchingContexts.map((item) => item.context) || [];
  };

  const fetchDictionaryMeanings = async (word) => {
    try {
      const response = await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`);
      const data = await response.json();

      if (Array.isArray(data) && data.length > 0) {
        const meanings = data[0].meanings.map((meaning, index) => ({
          partOfSpeech: meaning.partOfSpeech,
          definitions: meaning.definitions.map((definition, defIndex) => ({
            id: `${index}-${defIndex}`,
            definition: definition.definition,
            example: definition.example || null,
          })),
        }));

        return meanings;
      } else {
        console.error('Invalid API response for dictionary meaning:', data);
        return [];
      }
    } catch (error) {
      console.error('Error fetching dictionary meaning:', error);
      return [];
    }
  };

  const handleWordClick = async (word) => {
    setSelectedWord(word);

    const contextLinesResult = await fetchContextLines(word);
    const dictionaryMeaningsResult = await fetchDictionaryMeanings(word);

    setContextLines(contextLinesResult);
    setDictionaryMeanings(dictionaryMeaningsResult);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleAddButtonClick = async (id, word) => {
    try {
      const newToken = await refreshAccessToken();
      const response = await fetch(`${process.env.REACT_APP_AUTHENTICATION_API}/add_to_wordbank`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Bearer ${newToken}`,
        },
        body: new URLSearchParams({
          wordcontext_id: bookId,  //here bookId contains the bookname
          username: localStorage.getItem('username'),
          word: word,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to add word to word bank');
      }

      //console.log(`${word} with ${id} is added successfully`);

      setSnackbarMessage(`${word} added successfully to the word bank`);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error(error);

      setSnackbarMessage('Failed to add word to word bank');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const filteredWordContexts = wordContexts.filter((item) =>
    item.word.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedWordContexts = filteredWordContexts.sort((a, b) => {
    const sortOrder = sortAsc ? 1 : -1;
    return sortOrder * a.word.localeCompare(b.word);
  });

  const totalPages = Math.ceil(sortedWordContexts.length / itemsPerPage);

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', height: '100%' }}>
        {pdfData && (
          <iframe src={pdfData} title="PDF Viewer" width="100%" height="500px" frameBorder="0"></iframe>
        )}

        <Grid container spacing={3} style={{ marginTop: '20px', flex: 1 }}>
          <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
            <Card elevation={3} style={{ flex: 1 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Word List
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setSearchTerm('')}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <IconButton onClick={() => setSortAsc(!sortAsc)}>
                    <SortIcon />
                  </IconButton>
                </div>
                <List style={{ maxHeight: '600px', overflowY: 'auto', padding: '0' }}>
                  {sortedWordContexts.slice(startIndex, endIndex).map((item, index) => (
                    <Grid item xs={6} key={index}>
                      <ListItem button onClick={() => handleWordClick(item.word)}>
                        <ListItemText primary={item.word} />
                        {item.is_enable &&(
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleAddButtonClick(item.id, item.word)}
                          sx={{
                            margin: '10px',
                            fontWeight: 'bold',
                            backgroundColor: '#2196F3',
                            borderRadius: '8px',
                            color: '#FFFFFF',
                            '&:hover': {
                              backgroundColor: '#1565C0',
                            },
                          }}
                        >
                          +
                        </Button>
                        )}
                      </ListItem>
                    </Grid>
                  ))}
                </List>
                {totalPages > 1 && (
                  <div style={{ marginTop: '10px', textAlign: 'center' }}>
                    {Array.from({ length: totalPages }).map((_, page) => (
                      <Button
                        key={page}
                        variant={currentPage === page + 1 ? 'contained' : 'outlined'}
                        color="primary"
                        onClick={() => handlePageChange(page + 1)}
                      >
                        {page + 1}
                      </Button>
                    ))}
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
            <Card elevation={3} style={{ marginTop: '0px', flex: 1 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Context Lines
                </Typography>
                <List>
                  {selectedWord ? (
                    contextLines.map((line, index) => (
                      <ListItem key={index}>
                        <ListItemText primary={line} />
                      </ListItem>
                    ))
                  ) : (
                    <Typography>Select a word to view context lines.</Typography>
                  )}
                </List>
              </CardContent>
            </Card>

            <Card elevation={3} style={{ marginTop: '20px', flex: 1, overflowY: 'auto' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Dictionary Meanings
                </Typography>
                {selectedWord ? (
                  dictionaryMeanings.map((meaning, index) => (
                    <div key={index} style={{ marginBottom: '16px' }}>
                      <Typography variant="subtitle1" gutterBottom>
                        {meaning.partOfSpeech}
                      </Typography>
                      <List>
                        {meaning.definitions.map((def) => (
                          <ListItem key={def.id}>
                            <ListItemText
                              primary={def.definition}
                              secondary={def.example ? `Example: ${def.example}` : null}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </div>
                  ))
                ) : (
                  <Typography>Select a word to view dictionary meanings.</Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </DashboardLayout>
  );
};

export default PDFViewer;

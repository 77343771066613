import React, { useState, useEffect } from 'react';
import { Card, Grid, Typography, Button, Chip, Avatar } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import refreshAccessToken from 'layouts/authentication/authUtil';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { Skeleton } from '@mui/material';


const BookList = () => {
  const [query, setQuery] = useState('programming');
  const [books, setBooks] = useState([]);
  const [coverImages, setCoverImages] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchBooks = async (query, setStateFunction) => {
    try {
      const newToken = await refreshAccessToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/book/list_books`, {
        headers: {
          Authorization: `Bearer ${newToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        if (Array.isArray(data.books)) {
          setStateFunction(data.books);
          const coverImages = await fetchCoverImages(data.books);
          setCoverImages(coverImages);
          //console.log("images")
          //console.log(coverImages);
        } else {
          console.error('Invalid API response format:', data);
        }
      } else if (response.status === 401) {
        // Handle token refresh and retry
      } else {
        console.error('Error fetching book data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching book data:', error);
    } finally {
      setLoading(false);
    }
  };


  const coverImage = async (booktitle) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/book/get_cover_image/${booktitle}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);
        //console.log(booktitle, " : ", imageUrl);
        return imageUrl;
      } else if (response.status === 401) {
        // Handle token refresh and retry
      } else {
        console.error('Error fetching cover image:', response.statusText);
        return null;
      }
    } catch (error) {
      console.error('Error fetching cover image:', error);
      return null;
    }
  };


  const fetchCoverImages = async (books) => {
    const coverImagePromises = books.map(async (book) => {
      return { bookname: book.bookname, coverImage: await coverImage(book.bookname) };
    });

    return Promise.all(coverImagePromises);
  };

  useEffect(() => {
    fetchBooks(query, setBooks);
  }, [query]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        {loading ? (
          Array.from({ length: 8 }).map((_, index) => (
            <Grid item key={index} xs={12} md={6} lg={4} xl={3}>
              <Card
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.2s ease-in-out',
                  ':hover': {
                    transform: 'scale(1.03)',
                  },
                }}
              >
                <Skeleton variant="rectangular" width="100%" height={200} />
                <div style={{ padding: '16px', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                  <Skeleton height={30} style={{ marginBottom: '8px' }} />
                  <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
                    <Skeleton variant="circular" width={40} height={40} style={{ marginRight: '8px' }} />
                    <Skeleton variant="rectangular" width={100} height={30} />
                  </div>
                  <Skeleton height={20} style={{ marginBottom: '8px' }} />
                  <Skeleton height={20} style={{ marginBottom: '8px' }} />
                  <Skeleton height={20} width={80} style={{ marginBottom: '8px' }} />
                  <Skeleton variant="rectangular" width="50%" height={40} style={{ marginTop: 'auto' }} />
                </div>
              </Card>
            </Grid>
          ))
        ) : (
          books.map((book, index) => {
            const { bookname, coverImage } = coverImages.find((image) => image.bookname === book.bookname) || {};

            return (
              <Grid item key={index} xs={12} md={6} lg={4} xl={3}>
                <Card
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.2s ease-in-out',
                    ':hover': {
                      transform: 'scale(1.03)',
                    },
                  }}
                >
                  <img
                    src={coverImage || 'https://source.unsplash.com/300x200/?bookcover'}
                    alt={`Book cover for ${book.title}`}
                    style={{
                      width: '100%',
                      height: '200px',
                      objectFit: 'cover',
                    }}
                  />
                  <div
                    style={{
                      padding: '16px',
                      flexGrow: 1,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: '1.8rem',
                        fontWeight: 'bold',
                        marginBottom: '8px',
                      }}
                      gutterBottom
                    >
                      {book.title}
                    </Typography>
                    <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                      <Tooltip title={book.author}>
                        <Chip
                          avatar={<Avatar>{book.author.charAt(0)}</Avatar>}
                          label={book.author}
                          variant="outlined"
                          style={{ maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        />
                      </Tooltip>
                      <Chip label={book.language} variant="outlined" style={{ maxWidth: '80px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '8px' }} />
                    </div>
                    <Typography variant="subtitle1" style={{ fontSize: '1rem', marginBottom: '8px' }}>
                      Total Words: <br />
                      {book.total_words.toLocaleString()}
                    </Typography>
                    <Typography variant="subtitle1" style={{ fontSize: '1rem', marginBottom: '8px' }}>
                      Unique Words: <br />
                      {book.unique_words.toLocaleString()}
                    </Typography>
                    <Typography variant="subtitle1" style={{ fontSize: '1rem', marginBottom: '8px' }}>
                      Static Extensive Words: <br />
                      {book.static_extensive_reading_words.toLocaleString()}
                    </Typography>
                    <Typography variant="subtitle1" style={{ fontSize: '1rem', marginBottom: '8px' }}>
                      Static Intensive Words: <br />
                      {book.static_intensive_reading_words.toLocaleString()}
                    </Typography>

                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={`/viewbook/${book.bookname}`}
                      style={{ marginTop: 'auto' }}
                    >
                      View Book
                    </Button>
                  </div>
                </Card>
              </Grid>
            );
          })
        )}
      </Grid>
    </DashboardLayout>
  );
};

export default BookList;

/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Google, Facebook } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import Notification from "examples/Notification";


// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import '../authenticationstyle.css';
import { useEffect } from "react";



function SignIn() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [forgetemail, setforgetEmail] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] = useState('error');
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  const navigate = useNavigate();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleforgetEmailChange = (e) => {
    setforgetEmail(e.target.value);
  };
  const handleNavigation = () => {
    navigate('/authentication/sign-up')
  }

  const handleForgotPassword = () => {
    // Open the Forgot Password modal
    setShowForgotPasswordModal(true);
  };

  const handleCloseForgotPasswordModal = () => {
    // Close the Forgot Password modal
    setShowForgotPasswordModal(false);
  };

  const handleSignIn = async () => {
    try {
      // Make a POST request to the login API

      const response = await fetch(`${process.env.REACT_APP_AUTHENTICATION_API}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username, // Assuming username is used for login
          password: password,
        }),
        // agent: new (window.require('http')).Agent({
        //   rejectUnauthorized: false,
        // }),
      });


      const data = await response.json();


      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        // Redirect to the dashboard
        const tokenPayload = JSON.parse(atob(data.tokens.access.split('.')[1]));



        // Set user information in localStorage
        localStorage.setItem('token', data.tokens.access);
        localStorage.setItem('refresh', data.tokens.refresh);
        localStorage.setItem('isAdmin', tokenPayload.is_admin);
        localStorage.setItem('username', tokenPayload.sub);
        if (tokenPayload.is_admin) {
          navigate('/users');
        } else {
          navigate('/book');
        }

      } else {
        // Handle incorrect credentials or other errors
        //console.log('Incorrect credentials');
        setNotificationSeverity('error');
        setNotificationMessage(data.error);
        setNotificationOpen(true);
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error('Error during sign-in:', error);
      setNotificationSeverity('error');
      setNotificationMessage('Error during sign-in');
      setNotificationOpen(true);
    }
  };

  const responseGoogle = async (response) => {
    // Handle Google login response
    localStorage.clear();
    // Redirect to the dashboard or handle the user's information as needed
    if (response.credential) {
      localStorage.setItem('token', response.credential);
      try {
        // Assuming the token is a JWT, you may need to adjust this based on the actual structure
        const tokenPayload = JSON.parse(atob(response.credential.split('.')[1]));
        localStorage.setItem('username', tokenPayload.name);
        localStorage.setItem('isAdmin', false);

        // Send the Google token to your backend
        const backendResponse = await fetch(`${process.env.REACT_APP_AUTHENTICATION_API}/google-login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({
            googleToken: response.credential, // Pass the Google token to the backend
          }),
        });

        const responseData = await backendResponse.json();
        //console.log("backenresponse log")
        //console.log(responseData);
        if (backendResponse.ok) {
          const { tokens } = responseData;
          if (tokens) {
            const { access, refresh } = tokens;
            localStorage.setItem('token', access); // Storing access token for future use
            localStorage.setItem('refresh', refresh); // Storing refresh token for future use
            navigate('/book'); // Redirect to book page upon successful login
          } else {
            // Handle scenario where tokens are not returned
            alert('Error: Tokens not received');
          }
        } else {
          // Handle errors from backend
          alert(responseData.message || 'Error during sign in with Google');
        }


      } catch (error) {
        console.error('Error decoding Google Access Token:', error);
      }

    }
  };

 const handleSendResetLink = async () => {
  try {
    const url = new URL(`${process.env.REACT_APP_AUTHENTICATION_API}/forgot-password`);
    url.searchParams.append('email', forgetemail);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      // Reset link sent successfully
      //console.log('Reset link sent successfully');
      const data = await response.json();
      setNotificationSeverity('success');
      setNotificationMessage(data.message);
    } else {
      // Error sending reset link
      const errorData = await response.json().catch(() => ({}));
      console.error('Error:', errorData.error || 'Unknown error');
      setNotificationSeverity('error');
      setNotificationMessage(errorData.error || 'Unknown error');
    }
  } catch (error) {
    console.error('Network error:', error);
    setNotificationSeverity('error');
    setNotificationMessage('Network error. Please try again.');
  } finally {
    setNotificationOpen(true);
    handleCloseForgotPasswordModal();
  }
};

  // const responseFacebook = (response) => {
  //   // Handle Facebook login response
  //   //console.log(response);
  //   // Redirect to the dashboard or handle the user's information as needed
  //   if (response.accessToken) {
  //     localStorage.setItem('token', response.accessToken);
  //     navigate('/dashboard')
  //   }
  // };

  return (
    <CoverLayout
      title="Welcome back"
      description="Enter your username and password to sign in"
      image={curved9}
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Username
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SoftTypography>
          </SoftBox>

          <SoftInput
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </SoftTypography>
        </SoftBox>


        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" fullWidth onClick={handleSignIn}>
            sign in
          </SoftButton>
        </SoftBox>


        <SoftBox mt={2} textAlign="center">
          <SoftTypography
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
            style={{ cursor: 'pointer' }}
            onClick={handleForgotPassword}
          >
            Forgot Password?
          </SoftTypography>
        </SoftBox>
        {/* Social Media Login Options */}
        <SoftBox mt={4} className="social-login-icons">

          <GoogleLogin

            onSuccess={responseGoogle}
            onFailure={responseGoogle}

          />


          {/* <FacebookLogin
            appId="YOUR_FACEBOOK_APP_ID"
            autoLoad={false}
            fields="name,email,picture"
            callback={responseFacebook}
            render={(renderProps) => (
              <IconButton
                variant="outlined"
                color="info"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <Facebook />
              </IconButton>
            )}
          /> */}
        </SoftBox>

        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SoftTypography
              onClick={handleNavigation}
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
              style={{ cursor: 'pointer' }}
            >
              Sign up
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>

      <Notification
        open={notificationOpen}
        message={notificationMessage}
        severity={notificationSeverity}
        onClose={() => setNotificationOpen(false)}
      />


      {/* Forgot Password Modal */}
      <Dialog open={showForgotPasswordModal} onClose={handleCloseForgotPasswordModal}>
        <DialogTitle>Forgot Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your email address. We will send you a password reset link.
          </DialogContentText>
          <SoftInput
            autoFocus
            margin="dense"
            id="email"
            placeholder="Email Address"
            type="email"
            fullWidth
            value={forgetemail}
            onChange={handleforgetEmailChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForgotPasswordModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSendResetLink} color="primary">
            Send Reset Link
          </Button>
        </DialogActions>
      </Dialog>
    </CoverLayout>
  );
}

export default SignIn;

import React, { useState, useEffect } from 'react';
import { Grid, Typography, Card, CardContent, Button, Snackbar, TextField, InputAdornment, IconButton, LinearProgress } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { useParams } from 'react-router-dom';
import refreshAccessToken from 'layouts/authentication/authUtil';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import WordList from './WordList';

const PDFViewer = () => {
    const { bookId } = useParams();
    const [pdfData, setPdfData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const newToken = await refreshAccessToken();
                const pdfResponse = await fetch(`${process.env.REACT_APP_API_URL}/book/get_pdf/${bookId}`, {
                    headers: {
                        Authorization: `Bearer ${newToken}`
                    }
                });

                if (!pdfResponse.ok) {
                    throw new Error(`HTTP error! status: ${pdfResponse.status}`);
                }

                const resultBlob = await pdfResponse.blob();
                const url = URL.createObjectURL(resultBlob);
                setPdfData(url);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [bookId]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', height: '100%' }}>
                {loading ? (
                    <LinearProgress color="primary" style={{ position: 'absolute', width: '100%' }} />
                ) : error ? (
                    <Typography color="error" variant="h6">
                        Error loading PDF: {error}
                    </Typography>
                ) : (
                    pdfData && <iframe src={pdfData} title="PDF Viewer" width="100%" height="500px" frameBorder="0"></iframe>
                )}

                <WordList book={bookId} />
            </div>
        </DashboardLayout>
    );
};

export default PDFViewer;

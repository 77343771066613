import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import AddBookModal from "./AddBookModal";
import refreshAccessToken from "layouts/authentication/authUtil";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Container,
  CircularProgress,
  Pagination,
  Typography,
  Skeleton,
  Grid,
  Tooltip,
  Chip,
  Avatar,
  IconButton,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

const addBookToApi = async (newBook) => {
  try {
    const addedBook = await Promise.resolve(newBook);
    return addedBook;
  } catch (error) {
    console.error("Error adding book:", error);
    throw error; // Re-throw the error to propagate it to the caller
  }
};

const BookCardPlaceholder = () => (
  <Card>
    <Skeleton variant="rectangular" height={200} />
    <CardContent>
      <Skeleton height={20} style={{ marginBottom: '8px' }} />
      <Skeleton height={20} style={{ marginBottom: '8px' }} />
      <Skeleton height={20} width={80} style={{ marginBottom: '8px' }} />
    </CardContent>
  </Card>
);

const AdminBooks = () => {
  const [query, setQuery] = useState();
  const [books, setBooks] = useState([]);
  const [isAddBookModalOpen, setAddBookModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [coverImages, setCoverImages] = useState([]);



  const fetchBooks = async (query, setStateFunction) => {
    try {
      const newToken = await refreshAccessToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/book/list_books`, {
        headers: {
          Authorization: `Bearer ${newToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        if (Array.isArray(data.books)) {
          setStateFunction(data.books);
          const coverImages = await fetchCoverImages(data.books);
          setCoverImages(coverImages);
          //console.log("images")
          //console.log(coverImages);
        } else {
          console.error('Invalid API response format:', data);
        }
      } else if (response.status === 401) {
        // Handle token refresh and retry
      } else {
        console.error('Error fetching book data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching book data:', error);
    } finally {
      setLoading(false);
    }
  };



  const coverImage = async (booktitle) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/book/get_cover_image/${booktitle}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);
        //console.log(booktitle, " : ", imageUrl);
        return imageUrl;
      } else if (response.status === 401) {
        // Handle token refresh and retry
      } else {
        console.error('Error fetching cover image:', response.statusText);
        return null;
      }
    } catch (error) {
      console.error('Error fetching cover image:', error);
      return null;
    }
  };

  const fetchCoverImages = async (books) => {
    const coverImagePromises = books.map(async (book) => {
      return { bookname: book.bookname, coverImage: await coverImage(book.bookname) };
    });

    return Promise.all(coverImagePromises);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleViewBook = (bookId) => {
    // Navigate to the page where you can view the book based on bookId
    //console.log("Viewing book with ID:", bookId);
  };

  const handleAddBook = async (newBook) => {
    try {
      const addedBook = await addBookToApi(newBook);

      //console.log("in index js");
      //console.log(addedBook);
      setBooks((prevBooks) => [...prevBooks, addedBook]);


    } catch (error) {
      // Handle error if needed
    }
  };

  const handleDelete = async (bookname) => {
    try {
      const accessToken = await refreshAccessToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/book/delete?bookname=${bookname}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`, // Assuming you have accessToken available
        },
      });
  
      if (response.status === 200) {
        // Book deleted successfully
        //console.log('Book deleted successfully');
        alert("book deleted successfully");
        setQuery('');
        // Handle any UI updates if needed
      } else if (response.status === 404) {
        // Book not found
        console.error('The specified book does not exist.');

        alert("The specified book does not exist.");
        // Handle UI accordingly, maybe show an error message to the user
      } else {
        // Other errors
        console.error('Error deleting the book.');
        alert("Error deleting the book.");
        // Handle UI accordingly
      }
    } catch (error) {
      console.error('Error:', error);
      alert("Error deleting the book.");
      // Handle any unexpected errors
    }
  };
  

  useEffect(() => {
    fetchBooks(query, setBooks);
  }, [query]);

  const rowsPerPage = 10; // Number of rows per page
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        {/* Button to open the Add Book modal */}
        <SoftButton onClick={() => setAddBookModalOpen(true)} variant="outlined" color="primary">
          Add Book
        </SoftButton>

        {/* Loading indicator */}
        {loading && (
          <SoftBox textAlign="center" my={2}>
            <CircularProgress />
          </SoftBox>
        )}

        {/* List of books using cards with Skeleton placeholders */}
        {!loading && (
          <SoftBox mt={3}>
            <Grid container spacing={3}>
              {loading ? (
                Array.from({ length: 8 }).map((_, index) => (
                  <Grid item key={index} xs={12} md={6} lg={4} xl={3}>
                    <Card
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        transition: 'transform 0.2s ease-in-out',
                        ':hover': {
                          transform: 'scale(1.03)',
                        },
                      }}
                    >
                      <Skeleton variant="rectangular" width="100%" height={200} />
                      <div style={{ padding: '16px', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                        <Skeleton height={30} style={{ marginBottom: '8px' }} />
                        <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
                          <Skeleton variant="circular" width={40} height={40} style={{ marginRight: '8px' }} />
                          <Skeleton variant="rectangular" width={100} height={30} />
                        </div>
                        <Skeleton height={20} style={{ marginBottom: '8px' }} />
                        <Skeleton height={20} style={{ marginBottom: '8px' }} />
                        <Skeleton height={20} width={80} style={{ marginBottom: '8px' }} />
                        <Skeleton variant="rectangular" width="50%" height={40} style={{ marginTop: 'auto' }} />
                      </div>
                    </Card>
                  </Grid>
                ))
              ) : (
                books.map((book, index) => {
                  const { bookname, coverImage } = coverImages.find((image) => image.bookname === book.bookname) || {};

                  return (
                    <Grid item key={index} xs={12} md={6} lg={4} xl={3}>
                      <Card
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '100%',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                          transition: 'transform 0.2s ease-in-out',
                          ':hover': {
                            transform: 'scale(1.03)',
                          },
                        }}
                      >

                        <img
                          src={coverImage || 'https://source.unsplash.com/300x200/?bookcover'}
                          alt={`Book cover for ${book.title}`}
                          style={{
                            width: '100%',
                            height: '200px',
                            objectFit: 'cover',
                          }}
                        />
                        <div
                          style={{
                            padding: '16px',
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography
                            variant="h6"
                            style={{
                              fontSize: '1.8rem',
                              fontWeight: 'bold',
                              marginBottom: '8px',
                            }}
                            gutterBottom
                          >
                            {book.title}
                          </Typography>

                          <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                            <Tooltip title={book.author}>
                              <Chip
                                avatar={<Avatar>{book.author.charAt(0)}</Avatar>}
                                label={book.author}
                                variant="outlined"
                                style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                              />
                            </Tooltip>
                            <Chip label={book.language} variant="outlined" style={{ maxWidth: '80px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '8px' }} />
                          </div>
                          <Typography variant="subtitle1" style={{ fontSize: '1rem', marginBottom: '8px' }}>
                            Total Words: <br />
                            {book.total_words.toLocaleString()}
                          </Typography>
                          <Typography variant="subtitle1" style={{ fontSize: '1rem', marginBottom: '8px' }}>
                            Unique Words: <br />
                            {book.unique_words.toLocaleString()}
                          </Typography>
                          <Typography variant="subtitle1" style={{ fontSize: '1rem', marginBottom: '8px' }}>
                            Static Extensive Words: <br />
                            {book.static_extensive_reading_words.toLocaleString()}
                          </Typography>
                          <Typography variant="subtitle1" style={{ fontSize: '1rem', marginBottom: '8px' }}>
                            Static Intensive Words: <br />
                            {book.static_intensive_reading_words.toLocaleString()}
                          </Typography>

                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                              variant="contained"
                              color="primary"
                              component={Link}
                              to={`/viewbook/${book.bookname}`}
                            >
                              View Book
                            </Button>
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleDelete(book.bookname)} // Assuming you have a function to handle deletion
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>

                        </div>
                      </Card>
                    </Grid>
                  );
                })
              )}
            </Grid>
          </SoftBox>
        )}
      </SoftBox>

      {/* Add Book Modal */}
      <AddBookModal
        open={isAddBookModalOpen}
        onClose={() => setAddBookModalOpen(false)}
        onAddBook={handleAddBook}
      />


    </DashboardLayout>
  );
};

export default AdminBooks;

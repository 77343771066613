/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftAvatar from "components/SoftAvatar";
import { Chip, Divider } from "@mui/material";

function DefaultProjectCard({ image, label, title, description, action, authors, language, publishedDate, pageCount }) {
  const renderAuthors = authors.map(({ name }) => (
    <Chip key={name} label={name} variant="outlined" size="small" style={{ marginRight: 4 }} />
  ));

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
        marginBottom: 10, // Increase the margin bottom for more space between cards
        borderRadius: '12px', // Add a border radius for a rounded look
        border: '2px solid #e0e0e0', // Add a border for a highlighted look
        backgroundColor: 'white', // Set a default background color
        transition: 'box-shadow 0.3s ease-in-out', // Add a smooth transition for box-shadow
        '&:hover': {
          boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)', // Add a box-shadow on hover for a lift effect
        },
        
      }}
    >
      <SoftBox position="relative" width="100.25%" shadow="xl" borderRadius="xl">
        <CardMedia
          src={image}
          component="img"
          title={title}
          sx={{
            maxWidth: "100%",
            margin: 0,
            boxShadow: ({ boxShadows: { md } }) => md,
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </SoftBox>
      <SoftBox pt={3} px={0.5}>
        <SoftBox mb={1}>
          <SoftTypography
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            textGradient
          >
            {label}
          </SoftTypography>
        </SoftBox>
        <SoftBox mb={1}>
          {action.type === "internal" ? (
            <SoftTypography
              component={Link}
              to={action.route}
              variant="h5"
              textTransform="capitalize"
            >
              {title}
            </SoftTypography>
          ) : (
            <SoftTypography
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="h5"
              textTransform="capitalize"
            >
              {title}
            </SoftTypography>
          )}
        </SoftBox>
        <SoftBox mb={3} lineHeight={1.4}>
          <SoftTypography variant="body2" fontWeight="regular" color="text">
            {description}
          </SoftTypography>
        </SoftBox>
        <Divider sx={{ mb: 2 }} />
        <SoftBox mb={2}>
          <SoftTypography variant="body2">
            <strong>Language:</strong> {language || 'Not specified'}
          </SoftTypography>
          <SoftTypography variant="body2">
            <strong>Published Date:</strong> {publishedDate || 'Not specified'}
          </SoftTypography>
          <SoftTypography variant="body2">
            <strong>Page Count:</strong> {pageCount || 'Not specified'}
          </SoftTypography>
        </SoftBox>
        <Divider sx={{ mb: 2 }} />
        <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
          {renderAuthors}
          <SoftBox mt={2}>
            {action.type === "internal" ? (
              <SoftButton
                component={Link}
                to={action.route}
                variant="outlined"
                size="small"
                color={action.color}
              >
                {action.label}
              </SoftButton>
            ) : (
              <SoftButton
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="outlined"
                size="small"
                color={action.color}
              >
                {action.label}
              </SoftButton>
            )}
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}


// Setting default values for the props of DefaultProjectCard
DefaultProjectCard.defaultProps = {
  authors: [],
};

// Typechecking props for the DefaultProjectCard
DefaultProjectCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ),
  language: PropTypes.string, // Add this line
  publishedDate: PropTypes.string, // Add this line
  pageCount: PropTypes.number,
};

export default DefaultProjectCard;

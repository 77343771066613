// authUtils.js

const refreshAccessToken = async () => {
    let refreshToken = localStorage.getItem('refresh');
    //console.log('type of refresh token',typeof(refreshToken))
    //console.log('token',refreshToken);
    if(refreshToken==null){
      refreshToken=localStorage.getItem('token');
      //console.log('another token',refreshToken);
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_AUTHENTICATION_API}/refresh`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${refreshToken}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to refresh access token');
      }
  
      const data = await response.json();
      const newAccessToken = data.access_token;
  
      // Update local storage with the new access token
      localStorage.setItem('token', newAccessToken);
  
      return newAccessToken;
    } catch (error) {
      console.error('Error refreshing access token:', error);
      // Handle the error, e.g., redirect to login page
    }
  };
  
  export default refreshAccessToken;
  
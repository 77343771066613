import refreshAccessToken from 'layouts/authentication/authUtil';
import React, { useEffect, useState } from 'react';
import { Container, Card, Typography, CircularProgress, Paper, Button, Box } from '@mui/material';

export default function UserGraph() {
    const [graphImageUrl, setGraphImageUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [granularity, setGranularity] = useState('month'); // Default to 'month'
    const username = localStorage.getItem('username');

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const newToken = await refreshAccessToken();
                const response = await fetch(`${process.env.REACT_APP_AUTHENTICATION_API}/wordbank-graph/${username}?granularity=${granularity}`, {
                    headers: {
                        Authorization: `Bearer ${newToken}`,
                    }
                });

                if (response.ok) {
                    const blob = await response.blob();
                    const imageUrl = URL.createObjectURL(blob);
                    setGraphImageUrl(imageUrl);
                } else {
                    console.error('Failed to fetch graph data:', response.status);
                }
            } catch (error) {
                console.error('Error fetching graph data:', error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [username, granularity]); // Add granularity as a dependency to re-fetch data when it changes

    const handleGranularityChange = (newGranularity) => {
        setGranularity(newGranularity);
    };

    return (
        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5rem' }}>
            <Paper elevation={3} sx={{ padding: '1rem', marginBottom: '2rem', width: '100%', maxWidth: 1000, textAlign: 'center' }}>
                <Typography variant="h6" sx={{ marginBottom: '1rem' }}>Select Granularity for Graph</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                    <Button
                        variant={granularity === 'day' ? 'contained' : 'outlined'}
                        onClick={() => handleGranularityChange('day')}
                        sx={{ color: 'rgba(0, 0, 0, 0.87)' }} // Darker text color
                    >
                        Day
                    </Button>
                    <Button
                        variant={granularity === 'month' ? 'contained' : 'outlined'}
                        onClick={() => handleGranularityChange('month')}
                        sx={{ color: 'rgba(0, 0, 0, 0.87)' }} // Darker text color
                    >
                        Month
                    </Button>
                    <Button
                        variant={granularity === 'year' ? 'contained' : 'outlined'}
                        onClick={() => handleGranularityChange('year')}
                        sx={{ color: 'rgba(0, 0, 0, 0.87)' }} // Darker text color
                    >
                        Year
                    </Button>
                </Box>
            </Paper>
            <Card sx={{ width: '100%', maxWidth: 1000 }}>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <img src={graphImageUrl} alt={`Graph for ${username}`} style={{ width: '100%', height: 'auto' }} />
                        <div style={{ padding: '1rem' }}>
                            <Typography variant="h5">{`Graph for ${username}`}</Typography>
                        </div>
                    </>
                )}
            </Card>
        </Container>
    );
}

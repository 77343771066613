import React, { useState, useEffect } from "react";
import SoftTypography from "components/SoftTypography";
import Pagination from "@mui/material/Pagination";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SortIcon from "@mui/icons-material/Sort";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import BookIcon from "@mui/icons-material/MenuBook";
import refreshAccessToken from "layouts/authentication/authUtil";

const itemsPerRow = 5;
const rowsPerPage = 2;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function WordBank() {
  const [currentPage, setCurrentPage] = useState(1);
  const [words, setWords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedWords, setSortedWords] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const newToken = await refreshAccessToken();
        const response = await fetch(`${process.env.REACT_APP_AUTHENTICATION_API}/words_in_wordbank/${localStorage.getItem('username')}`, {
          headers: {
            Authorization: `Bearer ${newToken}`,
          }
        });
        const data = await response.json();

        if (response.ok) {
          setWords(data.Words);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching word bank:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filteredWords = words.filter(word =>
      word.word.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const sorted = filteredWords.sort((a, b) => {
      const compare = a.word.localeCompare(b.word);
      return sortOrder === "asc" ? compare : -compare;
    });
    setSortedWords([...sorted]);
  }, [searchTerm, words, sortOrder]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearch = () => {
    // Search logic already implemented in useEffect
  };

  const handleSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleDelete = async (wordId, word) => {
    try {
      if (confirm(`Are you sure you want to remove ${word} from your wordbank?`)) {


        // Make an API call to delete the word
        const newToken = await refreshAccessToken();
        const response = await fetch(`${process.env.REACT_APP_AUTHENTICATION_API}/delete_from_wordbank/${wordId}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${newToken}`,
          }
        });

        if (response.ok) {
          setWords(words.filter(word => word.id !== wordId));
          setSnackbarMessage("Word deleted successfully.");
          setSnackbarSeverity("success");
        } else {
          setSnackbarMessage("Failed to delete the word.");
          setSnackbarSeverity("error");
        }
      }
    } catch (error) {
      console.error("Error deleting word:", error);
      setSnackbarMessage("Failed to delete the word.");
      setSnackbarSeverity("error");
    }
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      {snackbarOpen && (
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <div>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
        </div>
      </Snackbar>
    )}
      <Container maxWidth="sm">
        <Paper elevation={3} style={{ padding: "20px", borderRadius: "16px" }}>
          <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
            <BookIcon fontSize="large" style={{ marginBottom: "8px" }} />
            <SoftTypography variant="h6" fontWeight="medium" mb={2} textAlign="center">
              Word Bank
            </SoftTypography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 2 }}>
            <TextField
              variant="outlined"
              value={searchTerm}
              placeholder="Search"
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
              fullWidth
            />
            <IconButton onClick={handleSort} aria-label="sort">
              <SortIcon />
            </IconButton>
          </Box>

          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px" }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Grid container spacing={2}>
                {sortedWords.map((word) => (
                  <Grid item key={word.id} xs={12} sm={6} md={3} lg={2}>
                    <Card variant="outlined">
                      <CardContent style={{ position: "relative" }}>
                        <SoftTypography variant="body2" textAlign="center">
                          {word.word}
                        </SoftTypography>
                        <IconButton
                          style={{ position: "absolute", top: 0, right: 0 }}
                          onClick={() => handleDelete(word.id, word.word)}
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              {sortedWords.length === 0 && (
                <Box textAlign="center" mt={2}>
                  <SoftTypography>No words found.</SoftTypography>
                </Box>
              )}

              {sortedWords.length > itemsPerRow * rowsPerPage && (
                <>
                  <Divider style={{ margin: "20px 0" }} />
                  <Pagination
                    count={Math.ceil(sortedWords.length / (itemsPerRow * rowsPerPage))}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    color="secondary"
                    size="large"
                    style={{ justifyContent: "center" }}
                  />
                </>
              )}
            </>
          )}

        </Paper>

      </Container>

    </>
  );
}

export default WordBank;

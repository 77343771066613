import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import AuthorsTable from './data/AuthorsTable'; // Update this path
import { Button, CircularProgress, Pagination } from '@mui/material';


function Tables() {
  const [authors, setAuthors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);


  // Function to refresh the access token
  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refresh');

    try {
      const response = await fetch(`${process.env.REACT_APP_AUTHENTICATION_API}/refresh`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${refreshToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to refresh access token');
      }

      const data = await response.json();
      const newAccessToken = data.access_token;

      // Update local storage with the new access token
      localStorage.setItem('token', newAccessToken);

      return newAccessToken;
    } catch (error) {
      console.error('Error refreshing access token:', error);
      // Handle the error, e.g., redirect to the login page
    }
  };

  const fetchData = async (page = 1, perPage = 10) => {
    try {
      setLoading(true);
      // Refresh access token before making the API request
      await refreshAccessToken();

      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/all?page=${page}&per_page=${perPage}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setAuthors(data.users);
      
      } else {
        console.error('Error fetching authors:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching authors:', error);
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          {loading ? (
       <SoftBox textAlign="center" my={2}>
       <CircularProgress />
     </SoftBox>
     
          ) : (
            <>
              <AuthorsTable authors={authors} setAuthors={setAuthors} fetchData={fetchData} />
              <SoftBox mt={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Pagination
                  count={Math.ceil(authors.length / 10)} // Assuming 10 items per page
                  page={currentPage}
                  onChange={(event, newPage) => handlePageChange(newPage)}
                  color="primary"
                />
              </SoftBox>
            </>
          )}
        </SoftBox>

      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;

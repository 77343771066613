import React, { useEffect, useState } from 'react';
import { Typography, List, ListItem, Grid, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import DictionaryMeanings from './DictionaryMeanings';

const Context = ({ selectedWord, contextLines }) => {
    const [meaningWord, setMeaningWord] = useState(selectedWord);

    useEffect(() => {
        setMeaningWord(selectedWord);
    }, [selectedWord]);

    useEffect(() => {
        //console.log('context called --Selected word:', meaningWord);
    }, [meaningWord]);

    const handleWordClick = (word) => {
        setMeaningWord(word);
    };

    const getHighlightedText = (text, highlight) => {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return (
            <span>
                {parts.map((part, index) =>
                    part.toLowerCase() === highlight.toLowerCase() ? (
                        <b key={index} style={{ textDecoration: 'underline' }}>
                            {part}
                        </b>
                    ) : (
                        part
                    )
                )}
            </span>
        );
    };

    const formatContextLine = (line, word) => {
        const wordIndex = line.toLowerCase().indexOf(word.toLowerCase());
        if (wordIndex === -1) return line;

        const start = Math.max(0, wordIndex - 100);
        const end = Math.min(line.length, wordIndex + word.length + 100);
        const truncatedLine = line.substring(start, end);

        const prefix =  '...';
        const suffix = '...';

        return prefix + truncatedLine + suffix;
    };

    return (
        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
            <Card elevation={3} style={{ marginTop: '0px', flex: 1, maxHeight: '70vh', overflowY: 'auto' }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Context Lines
                    </Typography>
                    <div>
                        {selectedWord ? (
                            contextLines.length ? (
                                <List>
                                    {contextLines[0].map((line, index) => (
                                        <ListItem
                                            key={index}
                                            style={{
                                                backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#e0e0e0',
                                                padding: '1rem',
                                                margin: '0.5rem 0',
                                            }}
                                        >
                                            <Typography component="div" style={{ overflowWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                                {formatContextLine(line, selectedWord).split(' ').map((word, wordIndex) => (
                                                    <span
                                                        key={wordIndex}
                                                        onClick={() => handleWordClick(word)}
                                                        style={{ marginRight: '8px', cursor: 'pointer' }}
                                                    >
                                                        {getHighlightedText(word, selectedWord)}
                                                    </span>
                                                ))}
                                            </Typography>
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <Typography>No context lines available.</Typography>
                            )
                        ) : (
                            <Typography>Select a word to view context lines.</Typography>
                        )}
                    </div>
                </CardContent>
            </Card>

            <Card elevation={3} style={{ marginTop: '20px', flex: 1, maxHeight: '50vh', overflowY: 'auto' }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Dictionary Meanings
                    </Typography>
            
                    <DictionaryMeanings selectedWord={meaningWord} />
                </CardContent>
            </Card>
        </Grid>
    );
};

Context.propTypes = {
    selectedWord: PropTypes.string,
    contextLines: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

export default Context;

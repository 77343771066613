import React, { useState } from 'react';
import BasicLayout from '../components/BasicLayout';
import { Card, Button, Snackbar } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import { Link, useParams } from 'react-router-dom';

export default function ForgetPassword() {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [showVerification, setShowVerification] = useState(false);

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleResetPassword = async () => {
        // Validation
        if (!password || !confirmPassword) {
            setMessage('Please fill in both password fields');
            setSnackbarOpen(true);
            return;
        }

        if (password !== confirmPassword) {
            setMessage('Passwords do not match');
            setSnackbarOpen(true);
            return;
        }

        // Call API to reset password
        try {
            // const url = new URL(`${process.env.REACT_APP_AUTHENTICATION_API}/reset-password`);
            // url.searchParams.append('token', token);
            // url.searchParams.append('new_password', password)
            const response = await fetch(`${process.env.REACT_APP_AUTHENTICATION_API}/reset-password/${token}?new_password=${password}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                // Password reset successful
                setMessage('Password reset successful');
                setSnackbarOpen(true);
                setShowVerification(true);
            } else {
                const data = await response.json();
                setMessage(data.error || 'Something went wrong');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('Network error. Please try again.');
            setSnackbarOpen(true);
        }
    };

    return (
        <BasicLayout>
            <Card>
                {showVerification ? (
                    <SoftBox p={3} mb={1} textAlign="center">
                        <SoftTypography variant="h6" fontWeight="medium" color="success">
                            Password reset successful.
                            <br />
                            <Link to="/authentication/sign-in" style={{ textDecoration: 'none' }}>
                                Click here to login.
                            </Link>
                        </SoftTypography>
                    </SoftBox>
                ) : (
                    <SoftBox p={3} mb={1} textAlign="center">
                        <SoftBox mb={2}>
                            <SoftInput
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </SoftBox>
                        <SoftBox mb={2}>
                            <SoftInput
                                type="password"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </SoftBox>
                        <Button onClick={handleResetPassword} variant="contained" color="primary">
                            Reset Password
                        </Button>
                    </SoftBox>
                )}
            </Card>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={message}
            />
        </BasicLayout>
    );
}

import React, { useEffect, useState } from 'react';
import { Typography, List, ListItem, ListItemText, Card, CardContent, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

const DictionaryMeanings = ({ selectedWord }) => {
  const [dictionaryMeanings, setDictionaryMeanings] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchDictionaryMeanings = async (word) => {
    setLoading(true);
    try {
      const response = await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`);
      const data = await response.json();

      if (Array.isArray(data) && data.length > 0) {
        const meanings = data[0].meanings.map((meaning, index) => ({
          partOfSpeech: meaning.partOfSpeech,
          definitions: meaning.definitions.map((definition, defIndex) => ({
            id: `${index}-${defIndex}`,
            definition: definition.definition,
            example: definition.example || null,
          })),
        }));

        return meanings;
      } else {
        console.error('Invalid API response for dictionary meaning:', data);
        return [];
      }
    } catch (error) {
      console.error('Error fetching dictionary meaning:', error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedWord) {
        const meanings = await fetchDictionaryMeanings(selectedWord);
        setDictionaryMeanings(meanings);
      }
    };

    fetchData();
  }, [selectedWord]);

  return (
    <Card elevation={3} style={{ marginTop: '20px', flex: 1, maxHeight: '50vh', overflowY: 'auto' }}>
      <CardContent>
      <Typography variant="h6" gutterBottom>
          {selectedWord ? (
            <>
              Meanings for <span style={{ fontWeight: 'bold', color: '#3f51b5' }}>{selectedWord}</span>
            </>
          ) : (
            'Select a word to view dictionary meanings'
          )}
        </Typography>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </div>
        ) : (
          <List>
            {dictionaryMeanings.length > 0 ? (
              dictionaryMeanings.map((meaning, index) => (
                <div key={index} style={{ marginBottom: '16px' }}>
                  <Typography variant="subtitle1" gutterBottom>
                    {meaning.partOfSpeech}
                  </Typography>
                  <List>
                    {meaning.definitions.map((def) => (
                      <ListItem key={def.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <ListItemText primary={def.definition} secondary={def.example ? `Example: ${def.example}` : null} />
                      </ListItem>
                    ))}
                  </List>
                </div>
              ))
            ) : (
              <Typography>No meanings available for &quot;{selectedWord}&quot;.</Typography>
            )}
          </List>
        )}
      </CardContent>
    </Card>
  );
};

DictionaryMeanings.propTypes = {
  selectedWord: PropTypes.string,
};

export default DictionaryMeanings;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Typography,
    List,
    ListItem,
    ListItemText,
    Card,
    CardContent,
    Button,
    Snackbar,
    TextField,
    InputAdornment,
    IconButton,
    Pagination,
    CircularProgress,
} from '@mui/material';
import refreshAccessToken from 'layouts/authentication/authUtil';
import SearchIcon from '@mui/icons-material/Search';
import AlphabeticalIcon from '@mui/icons-material/SortByAlpha';
import FrequencyIcon from '@mui/icons-material/Timeline';
import AddIcon from '@mui/icons-material/Add';
import Context from './Context';

const WordList = ({ book }) => {
    const [wordContexts, setWordContexts] = useState([]);
    const [selectedWord, setSelectedWord] = useState(null);
    const [contextLines, setContextLines] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [sortField, setSortField] = useState('word');
    const [sortAsc, setSortAsc] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [sorting, setSorting] = useState(false); // New state for sorting
    const itemsPerPage = 100;

    const fetchData = async () => {
        try {
            const formData = new FormData();
            formData.append('bookname', book);
            formData.append('username', localStorage.getItem('username'));
            const newToken = await refreshAccessToken();
            const wordContextsResponse = await fetch(`${process.env.REACT_APP_API_URL}/book/wordcontexts`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${newToken}`,
                },
            });
            const wordContextsData = await wordContextsResponse.json();
            //console.log(wordContextsData);
            if (wordContextsData.BooksWordContexts && Array.isArray(wordContextsData.BooksWordContexts)) {
                setWordContexts(wordContextsData.BooksWordContexts);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchContextLines = async (word) => {
        const matchingContexts = wordContexts.filter((item) => item.word === word);
        return matchingContexts.map((item) => item.context) || [];
    };

    const handleWordClick = async (word) => {
        setSelectedWord(word);
        const contextLinesResult = await fetchContextLines(word);
        //console.log(contextLinesResult);
        setContextLines(contextLinesResult);
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleSortClick = (field) => {
        setCurrentPage(1);
        setSorting(true);
        setSortField(field);
        setSortAsc(sortField !== field ? true : !sortAsc);
    };

    const handleAddButtonClick = async (id, word) => {
        try {
            const newToken = await refreshAccessToken();
            const formData = new FormData();
            formData.append('bookname', book);
            formData.append('username', localStorage.getItem('username'));
            formData.append('word', word);

            const response = await fetch(`${process.env.REACT_APP_AUTHENTICATION_API}/add_to_wordbank`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${newToken}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to add word to word bank');
            }

            //console.log(`${word} with ${id} is added successfully`);

            // Update only the specific word's is_enable property
            setWordContexts((prevContexts) =>
                prevContexts.map((item) =>
                    item.word === word ? { ...item, is_enable: false } : item
                )
            );

            setSnackbarMessage(`${word} added successfully to the word bank`);
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            console.error(error);

            setSnackbarMessage('Failed to add word to word bank');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const filteredWordContexts = wordContexts.filter((item) =>
        item.word.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedWordContexts = filteredWordContexts.sort((a, b) => {
        if (sortField === 'frequency') {
            return sortAsc ? a.word_frequency - b.word_frequency : b.word_frequency - a.word_frequency;
        } else {
            const sortOrder = sortAsc ? 1 : -1;
            return sortOrder * a.word.localeCompare(b.word);
        }
    });

    const totalPages = Math.ceil(sortedWordContexts.length / itemsPerPage);

    useEffect(() => {
        fetchData();
    }, [book]);

    useEffect(() => {
        if (sorting) {
            setSorting(false);
        }
    }, [sorting]);

    return (
        <>
            <Grid container spacing={3} style={{ marginTop: '20px', flex: 1 }}>
                <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Card elevation={3} style={{ flex: 1 }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Word List
                            </Typography>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <TextField
                                    label="Search"
                                    variant="outlined"
                                    size="small"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setSearchTerm('')}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <IconButton onClick={() => handleSortClick('word')}>
                                    <AlphabeticalIcon />
                                </IconButton>
                                <IconButton onClick={() => handleSortClick('frequency')}>
                                    <FrequencyIcon />
                                </IconButton>
                            </div>
                            {loading ? (
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Typography variant="body2" color="textSecondary" gutterBottom>
                                        Please wait...
                                    </Typography>
                                    <CircularProgress color="primary" style={{ marginTop: '10px' }} />
                                </div>
                            ) : (
                                <>
                                    <List style={{ maxHeight: '600px', overflowY: 'auto', padding: '0' }}>
                                        {sortedWordContexts.slice(startIndex, endIndex).map((item, index) => (
                                            <ListItem key={index} button onClick={() => handleWordClick(item.word)}>
                                                <ListItemText primary={item.word} />
                                                <ListItemText primary={`Frequency: ${item.word_frequency}`} />
                                                {item.is_enable && (
                                                    <IconButton
                                                        onClick={() => handleAddButtonClick(item.id, item.word)}
                                                        sx={{
                                                            margin: '10px',
                                                            fontWeight: 'bold',
                                                            backgroundColor: '#2196F3',
                                                            borderRadius: '8px',
                                                            color: '#FFFFFF',
                                                            '&:hover': {
                                                                backgroundColor: '#1565C0',
                                                            },
                                                        }}
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                )}
                                            </ListItem>
                                        ))}
                                    </List>
                                    {totalPages > 1 && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginTop: '10px',
                                                height: '50px',
                                            }}
                                        >
                                            <Pagination
                                                count={totalPages}
                                                page={currentPage}
                                                onChange={handlePageChange}
                                                variant="outlined"
                                                shape="rounded"
                                                boundaryCount={2}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Context selectedWord={selectedWord} contextLines={contextLines} />
            </Grid>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </>
    );
};

WordList.propTypes = {
    book: PropTypes.string.isRequired,
};

export default WordList;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* SignIn.css */
.social-login-icons {
    display: flex;
    gap: 8px; /* Adjust the gap between buttons as needed */
  }
  
  /* Customize the appearance of the icon buttons */
  .social-login-icons .MuiButtonBase-root {
    flex: 1 1;
    padding: 8px;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    background-color: transparent;
    border-radius: 4px; /* Add border-radius for rectangular shape */
  }
  
  /* Ensure the button label is visible */
  .social-login-icons .MuiButton-label {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  /* Add styles for better visibility on hover */
  .social-login-icons .MuiButtonBase-root:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
  `, "",{"version":3,"sources":["webpack://./src/layouts/authentication/authenticationstyle.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,aAAa;IACb,QAAQ,EAAE,6CAA6C;EACzD;;EAEA,iDAAiD;EACjD;IACE,SAAO;IACP,YAAY;IACZ,iCAAiC;IACjC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,6BAA6B;IAC7B,kBAAkB,EAAE,4CAA4C;EAClE;;EAEA,uCAAuC;EACvC;IACE,aAAa;IACb,mBAAmB;IACnB,QAAQ;EACV;;EAEA,8CAA8C;EAC9C;IACE,yBAAyB;IACzB,eAAe;EACjB","sourcesContent":["/* SignIn.css */\r\n.social-login-icons {\r\n    display: flex;\r\n    gap: 8px; /* Adjust the gap between buttons as needed */\r\n  }\r\n  \r\n  /* Customize the appearance of the icon buttons */\r\n  .social-login-icons .MuiButtonBase-root {\r\n    flex: 1;\r\n    padding: 8px;\r\n    transition: background-color 0.3s;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    border: 1px solid #ddd;\r\n    background-color: transparent;\r\n    border-radius: 4px; /* Add border-radius for rectangular shape */\r\n  }\r\n  \r\n  /* Ensure the button label is visible */\r\n  .social-login-icons .MuiButton-label {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 8px;\r\n  }\r\n  \r\n  /* Add styles for better visibility on hover */\r\n  .social-login-icons .MuiButtonBase-root:hover {\r\n    background-color: #f0f0f0;\r\n    cursor: pointer;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react';
import Card from '@mui/material/Card';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftBadge from 'components/SoftBadge';
import Table from 'examples/Tables/Table';
import { Button, Dialog, DialogTitle, DialogContent, Chip, IconButton } from '@mui/material';
import PropTypes from 'prop-types'; // Import PropTypes

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SoftInput from "components/SoftInput";
import refreshAccessToken from 'layouts/authentication/authUtil';
import Notification from 'examples/Notification';

const AuthorsTable = ({ authors, setAuthors, fetchData }) => {
  const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
  const [newUserData, setNewUserData] = useState({
    username: '',
    email: '',
    password: '',
  });

  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] = useState('success');

  const isEmailValid = (email) => {
    // Use a regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleAddUser = async () => {
    try {
      if (!isEmailValid(newUserData.email)) {
        setNotificationSeverity('error');
          setNotificationMessage('Invalid Email format');
          setNotificationOpen(true);
        return;
      }
      // Make an API request to add the new user using the provided URL
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(newUserData),
      });

      if (!response.ok) {
        // If the response status is not OK (e.g., 4xx or 5xx), check for unauthorized (401)
        if (response.status === 401) {
          // If unauthorized, refresh the access token
          const newAccessToken = await refreshAccessToken();

          // Retry the original request with the new access token
          const retryResponse = await fetch(`${process.env.REACT_APP_API_URL}/admin/add`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${newAccessToken}`,
            },
            body: JSON.stringify(newUserData),
          });

          // Check the response status after the retry
          if (!retryResponse.ok) {
            throw new Error(`Error: ${retryResponse.status} - ${retryResponse.statusText}`);
          }

          // Parse the JSON response after a successful retry
          const data = await retryResponse.json();

          // Handle successful addition after retry
          //console.log('User added successfully after token refresh:', data);

          setNotificationSeverity('success');
          setNotificationMessage('User added successfully');
          setNotificationOpen(true);

          // Close the modal after adding the user
          setAddUserModalOpen(false);
          fetchData();

          // Update the authors state with the new user
          // if (data && data.user) {
          //   setAuthors((prevAuthors) => [...prevAuthors, data.user]);
          // }
        } else {
          // If the response status is not 401, reject the promise
          setNotificationSeverity('error');
          setNotificationMessage('Error adding user');
          setNotificationOpen(true);
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
      } else {
        // If the response status is OK, proceed to parse the JSON
        const data = await response.json();

        // Handle successful addition
        //console.log('User added successfully:', data);
        setNotificationSeverity('success');
        setNotificationMessage('User added successfully');
        setNotificationOpen(true);

        // Close the modal after adding the user
        setAddUserModalOpen(false);
        fetchData();

        // Update the authors state with the new user
        // if (data && data.user) {
        //   setAuthors((prevAuthors) => [...prevAuthors, data.user]);
        // }
      }

      // Reset the form
      setNewUserData({
        username: '',
        email: '',
        password: '',
      });
    } catch (error) {
      // Handle addition error
      console.error('Error adding user:', error.message);
      setNotificationSeverity('error');
      setNotificationMessage('Error adding user');
      setNotificationOpen(true);
    }
  };




  const columns = [
    { name: 'username', align: 'left' },
    { name: 'email', align: 'left' },
    { name: 'status', align: 'center' }, // New column for status
    { name: 'delete', align: 'center' },
  ];


  const rows = authors.map((user) => ({
    username: user.username,
    email: user.email,
    status: (
      <Chip
        label={user.is_verified ? 'Verified' : 'Not Verified'}
        color={user.is_verified ? 'success' : 'error'}
      />
    ),
    delete: (

      <IconButton color='error' onClick={() => handleDelete(user.id, user.username)}>
        <DeleteIcon />
      </IconButton>
    ),
  }));

  const handleDelete = async (userId, un) => {
    try {
      const shouldDelete = window.confirm(`Are you sure you want to delete ${un}?`);

      if (!shouldDelete) {
        // If the user cancels the deletion, do nothing
        return;
      }
      // Implement the delete functionality here
      //console.log(`Delete user with ID: ${userId}`);

      // Make an API request to delete the user using the provided URL
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/delete/${userId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        // If the response status is not OK (e.g., 4xx or 5xx), check for unauthorized (401)
        if (response.status === 401) {
          // If unauthorized, refresh the access token
          const newAccessToken = await refreshAccessToken();

          // Retry the original request with the new access token
          const retryResponse = await fetch(`${process.env.REACT_APP_API_URL}/admin/delete/${userId}`, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${newAccessToken}`,
            },
          });

          // Check the response status after the retry
          if (!retryResponse.ok) {
            throw new Error(`Error deleting user with ID ${userId}: ${retryResponse.statusText}`);
          }

          // Handle successful deletion after retry
          //console.log(`User with ID ${userId} deleted successfully after token refresh.`);
          setNotificationSeverity('success');
          setNotificationMessage(`User ${un} deleted successfully`);
          setNotificationOpen(true);
          setAuthors((prevAuthors) => prevAuthors.filter((user) => user.id !== userId));
        } else {
          // Handle deletion error if the response status is not 401
          console.error(`Error deleting user with ID ${userId}: ${response.statusText}`);
          setNotificationSeverity('error');
          setNotificationMessage('Error deleting user');
          setNotificationOpen(true);
        }
      } else {
        // Handle successful deletion if the response status is OK
        //console.log(`User with ID ${userId} deleted successfully.`);
        setNotificationSeverity('success');
        setNotificationMessage(`User ${un} deleted successfully`);
        setNotificationOpen(true);
        setAuthors((prevAuthors) => prevAuthors.filter((user) => user.id !== userId));
      }
    } catch (error) {
      // Handle deletion error
      console.error('Error deleting user:', error.message);
      setNotificationSeverity('error');
      setNotificationMessage('Error deleting user');
      setNotificationOpen(true);
    }
  };
  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  return (
    <Card>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <SoftTypography variant="h6">Users Table</SoftTypography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />} // Add an add icon
          onClick={() => setAddUserModalOpen(true)} // Open the modal when clicked
        >
          Add User
        </Button>
      </SoftBox>
      <SoftBox
        sx={{
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                `${borderWidth[1]} solid ${borderColor}`,
            },
          },
        }}
      >
        <Table columns={columns} rows={rows} sx={{ padding: 10 }} />
      </SoftBox>

      {/* Add User Modal */}
      <Dialog
        open={isAddUserModalOpen}
        onClose={() => setAddUserModalOpen(false)}
        fullWidth
        maxWidth="sm" // You can adjust the size as needed, e.g., "md", "lg", etc.
      >
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <SoftBox mb={2}>
            <SoftInput
              label="Username"
              name="username"
              value={newUserData.username}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              placeholder="Enter username"
            />
          </SoftBox>
          <SoftBox mb={2}>
            <SoftInput
              label="Email"
              name="email"
              value={newUserData.email}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              placeholder="Enter email"
            />
          </SoftBox>
          <SoftBox mb={2}>
            <SoftInput
              label="Password"
              name="password"
              value={newUserData.password}
              onChange={handleInputChange}
              type="password"
              fullWidth
              margin="normal"
              placeholder="Enter password"
            />
          </SoftBox>
          <Button variant="contained" color="primary" onClick={handleAddUser}>
            Add User
          </Button>
        </DialogContent>
      </Dialog>
      {/* Notification component */}
      <Notification
        open={notificationOpen}
        message={notificationMessage}
        severity={notificationSeverity}
        onClose={handleNotificationClose}
      />
    </Card>
  );
};

// Add PropTypes validation
AuthorsTable.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    })
  ).isRequired,
  setAuthors: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
};

export default AuthorsTable;
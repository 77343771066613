/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";
import ReCAPTCHA from "react-google-recaptcha"

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";
import Notification from "examples/Notification";

function SignUp() {
  const [agreement, setAgremment] = useState(true);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleSetAgremment = () => setAgremment(!agreement);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const navigate = useNavigate();
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationSeverity, setNotificationSeverity] = useState('error');

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleNavigation = () => {
    navigate('/authentication/sign-in')
  }
  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignUp = async () => {
    try {
      if (!isEmailValid(email)) {
        setNotificationSeverity('error');
        setNotificationMessage('Invalid email format');
        setNotificationOpen(true);
        return;
      }
      if (password !== confirmPassword) {
        setNotificationSeverity('error');
        setNotificationMessage('Passwords do not match');
        setNotificationOpen(true);
        return;
      }

      if (!agreement) {
        setNotificationSeverity('error');
        setNotificationMessage('You must agree to the Terms and Conditions to proceed');
        setNotificationOpen(true);
        return;
      }

      if (!recaptchaValue) {
        setNotificationSeverity('error');
        setNotificationMessage('Please complete the reCAPTCHA challenge');
        setNotificationOpen(true);
        return;
      }


      const response = await fetch(`${process.env.REACT_APP_AUTHENTICATION_API}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          email: email,
          password: password,
        }),
      });
      //console.log(response)
      if (response.ok) {
        // Registration successful, you may redirect or perform other actions
        setSignUpSuccess(true);
        // navigate('/authentication/sign-in')
        //console.log('Registration successful');
      } else {
        // Handle registration failure
        console.error('Registration failed:', response.statusText);
        setNotificationSeverity('error');
        setNotificationMessage('Error during sign-up');
        setNotificationOpen(true);
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error('Error during sign-up:', error);
      setNotificationSeverity('error');
      setNotificationMessage('Error during sign-up');
      setNotificationOpen(true);
    }
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  return (
    <BasicLayout
      title="Welcome!"
      description=""
      image={curved6}
    >
      <Card>
        {signUpSuccess ? (
          <SoftBox p={3} mb={1} textAlign="center">
            <SoftTypography variant="h6" fontWeight="medium" color="success">
              A verification email has been sent to your registered email address.
              <br />
              Please check your mail and verify it.
              <br />
              After verification,{" "}
              <Link to="/authentication/sign-in" style={{ textDecoration: 'none' }}>
                click here
              </Link>{" "}
              to sign in.
            </SoftTypography>
          </SoftBox>
        ) : (
          <SoftBox>
            <SoftBox p={3} mb={1} textAlign="center">
              <SoftTypography variant="h5" fontWeight="medium">
                Register with
              </SoftTypography>
            </SoftBox>
            <SoftBox mb={2}>
              <Socials />
            </SoftBox>
            <Separator />
            <SoftBox pt={2} pb={3} px={3}>
              <SoftBox component="form" role="form">
                <SoftBox mb={2}>
                  <SoftInput
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftInput
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftInput
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftInput
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </SoftBox>
                <SoftBox display="flex" alignItems="center">
                  <Checkbox checked={agreement} onChange={handleSetAgremment} />
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    onClick={handleSetAgremment}
                    sx={{ cursor: "poiner", userSelect: "none" }}
                  >
                    &nbsp;&nbsp;I agree the&nbsp;
                  </SoftTypography>
                  <SoftTypography
                    component="a"
                    href="#"
                    variant="button"
                    fontWeight="bold"
                    textGradient
                  >
                    Terms and Conditions
                  </SoftTypography>

                </SoftBox>
                <SoftBox>
                
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                    onChange={handleRecaptchaChange}
                  />
                </SoftBox>
                <SoftBox mt={4} mb={1}>
                  <SoftButton variant="gradient" color="dark" fullWidth onClick={handleSignUp}>
                    sign up
                  </SoftButton>
                </SoftBox>
                <SoftBox mt={3} textAlign="center">
                  <SoftTypography variant="button" color="text" fontWeight="regular">
                    Already have an account?&nbsp;
                    <SoftTypography
                      onClick={handleNavigation}
                      variant="button"
                      color="dark"
                      fontWeight="bold"
                      textGradient
                      style={{ cursor: 'pointer' }}
                    >
                      Sign in
                    </SoftTypography>
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        )}
      </Card>

      {/* Notification component */}
      <Notification
        open={notificationOpen}
        message={notificationMessage}
        severity={notificationSeverity}
        onClose={handleNotificationClose}
      />
    </BasicLayout>
  );
}

export default SignUp;
